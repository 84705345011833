import React, { useState } from 'react';
import '../styles/index.scss';
import Layout from '../components/layout';
import Personenwagen from '../components/NeuwagenSection/personenwagen';
import Elektrofahrzuge from '../components/NeuwagenSection/elektrofahrzuge';
import Hybridfahrzeuge from '../components/NeuwagenSection/hybridfahrzeuge';
import RenaultSport from '../components/NeuwagenSection/renaultSport';
import Nutzfahrzeuge from '../components/NeuwagenSection/nutzfahrzeuge';
import NeuwagenButtons from '../components/NeuwagenButtons/neuwagenButtons';
import SEO from '../components/SEO/SEO';

const Neuwagen = () => {
  const [selectedComponent, setSelectedComponent] = useState('Personenwagen');

  return (
    <>
      <Layout>
        <SEO title='Neuwagen' description='Renault Neuwagen Angebot.' />
        <section className='neuwagen'>
          <div className='headerContainer'>
            <h1>
              Renault <span className='yellow'>Neuwagenpalette</span>
            </h1>
          </div>
          <NeuwagenButtons
            onSelect={setSelectedComponent}
            selected={selectedComponent}
          />
          {selectedComponent === 'Personenwagen' && <Personenwagen />}
          {selectedComponent === 'Elektrofahrzeuge' && <Elektrofahrzuge />}
          {selectedComponent === 'Hybridfahrzeuge' && <Hybridfahrzeuge />}
          {/*          {selectedComponent === 'Renault Sport' && <RenaultSport />} */}
          {selectedComponent === 'Nutzfahrzeuge' && <Nutzfahrzeuge />}
        </section>
      </Layout>
    </>
  );
};

export default Neuwagen;
