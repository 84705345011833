// extracted by mini-css-extract-plugin
export var btnActive = "styles-module--btnActive--4a94b";
export var btnContainer = "styles-module--btn-container--53ed5";
export var button = "styles-module--button--8fd58";
export var buttonMd = "styles-module--button-md--655f2";
export var carousel = "styles-module--carousel--c19df";
export var grey = "styles-module--grey--985ec";
export var greyFont = "styles-module--greyFont--185fb";
export var headerContainer = "styles-module--headerContainer--8904b";
export var spanBold = "styles-module--spanBold--616cb";
export var yellow = "styles-module--yellow--bc98f";