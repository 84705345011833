import React from 'react';
import * as styles from './styles.module.scss';
import neuwagenButtons from '../../config/libs/neuwagenButtons';

const NeuwagenButtons = ({ onSelect, selected }) => {
  const clickHandler = (event) => {
    onSelect(event.target.value);
  };

  const buttons = Object.values(neuwagenButtons).map((item, index) => (
    <button
      className={`${styles.button} ${
        item === selected && `${styles.btnActive}`
      }`}
      key={index}
      value={item}
      onClick={clickHandler}
    >
      {item}
    </button>
  ));
  return <div className={`col ${styles.btnContainer}`}>{buttons}</div>;
};

export default NeuwagenButtons;
