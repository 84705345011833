import React from 'react';
import * as styles from './style.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Personenwagen = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPersonenwagen(sort: { fields: createdAt, order: ASC }) {
        nodes {
          info
          pdf
          src
          title
          image {
            gatsbyImage(width: 500, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const {
    allContentfulPersonenwagen: { nodes },
  } = data;

  return (
    <section>
      <div className={`container-fluid ${styles.carContainer}`}>
        {nodes.map((item, index) => {
          return (
            <div className={`col-lg-3 col-md-6 ${styles.col}`} key={index}>
              <div>
                <a href={item.src}>
                  <GatsbyImage image={item.image.gatsbyImage} />
                </a>
              </div>
              <div className={styles.content}>
                <p>{item.title}</p>
                <span className='greyFont'>{item.info}</span>
              </div>
              <a href={item.pdf}>
                <button className='button-md'>Preise Liste</button>
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Personenwagen;
